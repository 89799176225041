import axios from "axios";
import { useAuth } from "hooks";
import { get } from "lodash-es";

const { REACT_APP_BASE_URL: API_BASE_URL } = process.env;

export const useAxios = () => {
  const auth = useAuth();

  const options = {
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };

  const client = axios.create(options);

  client.interceptors.request.use(
    config => {
      const { access_token = "" } = auth.token;
      config.headers.common.Authorization = `Bearer ${access_token}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (get(error, "response.status") === 401) {
        auth.signout();
      }
      return Promise.reject(error);
    }
  );

  return client;
};
